<template>
  <div class="footer">
    <p>
      © Hop Fung Iron Works Ltd. All rights reserved.
    </p>
  </div>
</template>
<script>
export default {
  name: 'HfFooter'
}
</script>
<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 30px;
}
</style>
