export default {
  home: '首頁',
  about_us: '關於我們',
  our_strengths: '公司簡介及相關證書',
  ManufacturingProcess: '鑄件生產過程',
  pipesManufacturingProcess: '喉管生產流程',
  contactus: '聯絡我們',
  HopFungIronWorksLtd: '合豐鐵工廠有限公司',
  DownloadLink: '產品目錄,公司簡介及工程紀錄下載',
  ProductShow: '產品展示',
  Experienced: '"五十年經驗 • 品質優良 • 專業認證"',
  DecadesofExperience: '五十年經驗',
  about1: '於1963年，合豐鐵工廠有限公司正式成立，設廠於元朗屏山，專門鑄造各種生鐵產品。',
  about2: '時至今日，本公司憑著多年經驗及專業的生產技術，為香港多個大型基建項目，供應高品質及符合規格的生鐵排污物料。',
  about3: '品資認證',
  about4: '本公司供應的各種貨品，生產過程均獲得ISO 9001:2015認證。',
  about5: '本廠所生產的生鐵物料，均通過本港認可化驗所BSEN1452及BSEN 1561之拉力測試，本廠所生產的球鐵物料，亦通過BSEN1563拉力測試，絕對符合政府要求，相關證書，可按',
  about5_1: '此處',
  about5_2: '下載。',
  about5_3: '工程紀錄',
  about5_4: '自成立以來，本廠的產品曾供應至本港多個大型基建項目，備受各建築公司青睞，時至今日，在香港多個標誌性建築，例如赤鱲角機場，香港迪士尼樂園等，均可看見本公司的產品。',
  about5_5: '如需索取本公司的過往工程紀錄及地盤批信等，可按',
  about5_6: '此處',
  about5_7: '。',
  about6: '我們的團隊',
  about7: '我們的管理人員，均有多年的鑄造經驗，熟悉各個生產步驟，並充分了解客人需要。',
  about8: '我們的前線工人，均抱有敬業樂業的工作精神，在安全第一的前提下，有效率地生產出品質優良的產品。',
  about9: '品質監控',
  certificates: '請點擊此處查看文件及證書',
  about10: '本廠生產的貨品，在來港前均經過嚴格的物料及水壓測試，確保所有供應地盤的物料，均維持統一的優良規格，符合有關部門要求。',
  about11: '貨期快捷為配合香港瞬息萬變的工作節奏，方便客人如期完成工程，本廠大部分常用貨品，己採用全機械化生產，並於香港設有貨倉常備充足現貨，確保從客戶下單一個月內，可以迅速交貨至地盤(訂造產品除外)。',
  about12: '運輸車隊',
  about13: '本公司已在港添置數部不同型號的吊臂車，確保無論客戶的地盤位於本港任何地區任何路況，貨物都可以準時送到。',
  about14: '始於1963年',
  about15: '貨期快捷',
  about16: '為配合香港瞬息萬變的工作節奏，方便客人如期完成工程，本廠大部分常用貨品，己採用全機械化生產，並於香港設有貨倉常備充足現貨，確保從客戶下單一個月內，可以迅速交貨至地盤(訂造產品除外)。',
  mf1: '各款模具',
  mf2: '鋁製模具',
  mf3: '鋁製模具能配合全自動造型機使用，大大縮短生產時間。用機械切割出的鋁模準繩度亦較傳統模具更高，同時由於鋁模能承受更高壓力，生產的鑄造品表面亦較為平滑。',
  mf4: '木製模具及發泡膠模具',
  mf5: '傳統木製模具由我們有多年經驗的師傅全人手製造，可隨客人來圖即時起模，適合生產各種訂製組件，同時亦適用於各種尺寸較大的鑄件。為加快生產效率，本廠近年亦開始轉用發泡膠模具，大幅縮減造模時間。',
  mf6: '濕砂造型',
  mf7: '全自動造型',
  mf8: '全自動造型機配合鋁製模具，大幅縮短生產時間的同時，亦能令鑄件表面更平滑，提供價廉物美的鑄造產品。',
  mf9: '半自動造型',
  mf10: '使用半自動造型機配合木製模具，工人先在模具上鋪上鑄造用砂，再用砂箱及機器將砂壓實。由於不受機器尺寸所限，適用於製造井蓋等大型鑄件',
  mf11: '傳統造型',
  mf12: '傳統沙地造型產量雖不及機械造型，但卻適用於各種大小及形狀的模具，適用於各種特製產品。',
  mf13: '鑄造過程',
  mf14: '我們採用來源可靠的優質原料，每一爐鐵水在倒模前都經過成份分析，確保質量統一。',
  mf15: '我們可按客戶的要求，鑄造出各種生鐵及球鐵產品。多年的鑄造經驗以及優秀的品質，確保我們在行業中的領導地位。',
  c1: '聯絡我們',
  c2: '廠址',
  c3: '元朗潭尾青山公路米埔段168號 (洋葵徑側)',
  c4: '電話',
  c5: '傳真',
  c6: '電郵',
  c6_1: '郵遞處',
  c6_2: '香港新界元朗郵政信箱1385號',
  c7: '陳榮昌',
  c8: '陳冠文',
  c9: '陳滙璋',
  c10: '位置',
  c11: '陳冠傑',
  parameters: '參數',
  attachment: '附件',
  att_1: '產品目錄',
  att_2: '產品證書及相關文件',
  intro: '簡介',
  select: '請選擇',
  isApp: '暫不支援手機版本<br/>如需查看本網頁，請用電腦瀏覽',
  plzSelect: '請根據所需要求選擇貨品',
  // 适配app端调整
  options: '選項',
  map: '請在地圖上搜尋 ',
  map2: '元朗潭尾青山公路米埔段168號 (洋葵徑側)'
}
