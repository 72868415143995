<template>
  <hf-header-layout>
    <!-- Banner -->
    <section id="banner" v-if="!isApp">
       <el-carousel height="80vh" :interval="9000" >
        <el-carousel-item v-for="(image,index) in bannerImgUrls" :key='`image_${index}`'>
          <div :style="{background: 'url(' + image + ')'}" class="banner--image" />
        </el-carousel-item>
      </el-carousel>
      <div class="banner-inner" :class="language==='en'?'':'banner-ch'">
        <h2>{{$t('HopFungIronWorksLtd')}}<br>{{$t('Experienced')}}</h2>
      </div>
    </section>
    <section v-else class="banner-app">
      <el-carousel height="30vh" :interval="9000" arrow="never">
        <el-carousel-item v-for="(image,index) in bannerImgUrls" :key="`image_${index}`">
          <div :style="{background: 'url(' + image + ')'}" class="banner-app--image" />
        </el-carousel-item>
      </el-carousel>
      <div class="banner-inner" :class="language==='en'?'':'banner-ch'">
        <h2  style="color: #fff">
          {{$t('HopFungIronWorksLtd')}}
          <br />
          {{$t('Experienced')}}
        </h2>
      </div>
    </section>
    <!-- Main -->
    <div id="main">
      <center><h2>{{$t('ProductShow')}}</h2></center>
      <section class="tiles" v-if="!isApp">
        <article class="style1"
                 v-for="(category,index) in categoryList" :key="index">
                    <span class="image">
                      <img :src="category.imgUrls[0]" :alt="category.imgUrls[0]"/>
                    </span>
          <a @click="toChild(category)" style="cursor: pointer">
            <span style="background-color:#9F9F9F"> {{language !== 'en'?category.title:category.enTitle}}</span>
          </a>
        </article>
      </section>
       <!-- app -->
      <section v-else class="secton-app">
        <div class="secton-app__item" v-for="(category,index) in categoryList" :key="index">
          <img :src="category.imgUrls[0]" style="width: 100%" alt />
          <div @click="toChild(category)"
            style="cursor: pointer;"
            class="secton-app__link">
            <span style="background-color:#9F9F9F;width:auto;font-size: 14px">{{language !== 'en'?category.title:category.enTitle}}</span>
          </div>
        </div>
      </section>
    </div>
    <!-- Footer -->
    <footer id="footer" v-if="!isApp">
      <div class="copyright">
        <p>&copy; Hop Fung Iron Works Ltd. All rights reserved. Tel: +852 2476 7328 Email: <a href="mailto:manager@hopfung.com.hk" target="_blank">manager@hopfung.com.hk</a></p>
      </div>
    </footer>
        <hf-footer v-else/>
  </hf-header-layout>
</template>

<script>

import HfHeaderLayout from './components/hf-header-layout'
import HfFooter from './components/hf-footer'

export default {
  name: 'home',
  components: { HfHeaderLayout, HfFooter },
  data () {
    return {
      pid: '',
      ids: [],
      categoryList: [],
      bannerImgUrls: [],
      language: sessionStorage.getItem('language'),
      isApp: false,
      screenWidth: document.body.clientWidth // 屏幕宽度
    }
  },
  mounted () {
    // 监听窗口大小
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  watch: {
    screenWidth: {
      handler (val) {
        this.isApp = val < 768
      },
      immediate: true
    },
    $route: {
      async handler (to, from) {
        if (to.query.pid) {
          this.pid = to.query.pid
        }
        await this.initData()
      },
      deep: true,
      immediate: true
    }
  },
  async created () {
    await this.initData()
    this.language = sessionStorage.getItem('language')
  },
  methods: {
    async toChild (category) {
      const res = await this.$http.get(`https://hopfung.com.hk:8443/hop-fung/app/categories?pId=${category.id}&locale=zh-hk`)
      const cArr = await res.data.data
      // console.log('cArr', cArr)
      if (cArr && cArr.length > 0) {
        this.$router.push({ path: `/category?pid=${category.id}` })
      } else {
        this.$router.push({ path: `/product?categoryId=${category.id}` })
      }
    },
    async initData () {
      let res = await this.$http.get('https://hopfung.com.hk:8443/hop-fung/app/v2/home-page')
      const data = res && res.data && res.data.data
      this.bannerImgUrls = data.bannerImgUrls
      if (this.pid) {
        const res = await this.$http.get(`https://hopfung.com.hk:8443/hop-fung/app/categories?pId=${this.pid}&locale=zh-hk`)
        // console.log('res', res.data.data)
        this.categoryList = res.data.data
        return res.data.data
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.banner-inner {
  width: 100%;
  position: absolute;
  top: 42%;
  z-index: 10;
  text-align: center;
  font-size: 38px;
}
.banner--image {
  background-position: center 0 !important;
  height: 100% !important;
  width: 100% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 1.5s ease, visibility 1.5s;
}
.banner-app {
  position: relative;
  height: 30vh;
  &--image {
    background-position: center 0 !important;
    height: 100% !important;
    width: 100% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    transition: opacity 1.5s ease, visibility 1.5s;
  }
}
.secton-app {
  display: flex;
  flex-flow: row wrap;
  &__item {
    position: relative;
    width: 50%;
    padding: 15px;
  }
  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
}
</style>
