<template>
  <hf-header-layout>
    <section id="banner">
       <el-carousel height="80vh" :interval="9000">
        <el-carousel-item v-for="(image,index) in bannerImgUrls" :key='`image_${index}`'>
          <div :style="{background: 'url(' + image + ')'}" class="banner--image" />
        </el-carousel-item>
      </el-carousel>
      <div class="banner-inner" :class="language==='en'?'':'banner-ch'">
        <h2>合豐鐵工廠有限公司<br>”六十年經驗&nbsp;&#8226;&nbsp;專業認證&nbsp;&#8226;&nbsp;品質優良”</h2>
      </div>
    </section>
    <!-- One -->
    <section id="one" class="wrapper style1">
      <div class="container">
        <header class="major">
          <h2>六十年經驗 </h2>
          <p>於1963年，合豐鐵工廠有限公司正式成立，設廠於元朗屏山，專門鑄造各種生鐵產品。</p>
        </header>
        <div class="slider">
          <div class="viewer">
            <div class="slide">
              <img src="../images/de1.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Two -->
    <div class="spotlights1">
      <div class="spotlight1">
        <div class="image fit">
          <img src="../images/2.jpg" alt=""/>
        </div>
        <div class="content">
          <p>時至今日，<br>本公司憑著多年經驗及專業的生產技術， </p>
        </div>
      </div>
      <div class="spotlight1">
        <div class="image fit alt">
          <img src="../images/3.jpg" alt=""/>
        </div>
        <div class="content">
          <p>為香港多個大型基建項目，<br>供應高品質及符合規格的生鐵排污物料。</p>
        </div>
      </div>
    </div>

    <!-- Three -->
    <div class="wrapper style2 ">
      <div class="inner">
        <header class="major">
          <h2>品質認證</h2>
        </header>
        <p>本公司供應的各種貨品，生產過程均獲得ISO 9001:2015認證。<br>本廠所生產的生鐵物料，均通過本港認可化驗所BSEN1452及BSEN 1561之拉力測試。<br>本廠所生產的球鐵物料，亦通過BSEN1563拉力測試，絕對符合政府要求。
        </p>
        <span class="img-3item"><img src="../images/1452.png" alt=""/></span>
        <span class="img-3item"><img src="../images/1561.png" alt=""/></span>
        <span class="img-3item"><img src="../images/1563.png" alt=""/></span>
        <ul class="actions special">
          <li><a href="https://drive.google.com/drive/folders/1at9OpMp-PkPvBBo7Gt0uToMhZWos3Q_h"
            target="_blank" class="button large">點此下載相關證書</a></li>
        </ul>
      </div>
    </div>

    <!-- four -->
    <div class="wrapper style3 ">
      <div class="inner">
        <header class="major">
          <h2>工程紀錄</h2>
          <p>自成立以來，本廠的產品曾供應至本港多個大型基建項目，備受各建築公司青睞。<br>時至今日，在香港多個標誌性建築，例如赤鱲角機場，香港迪士尼樂園等，均可看見本公司的產品。</p>
        </header>
        <span class="img-2item"><img src="../images/job1.png" alt=""/></span>
        <span class="img-2item"><img src="../images/job2.png" alt=""/></span>
        <ul class="actions special">
          <li><a target="_blank"
                 href="https://drive.google.com/drive/folders/1dTRu-5b2A_qRzRiEQAmL26YBNClMma1I" class="button large">索取本公司的過往工程紀錄及地盤批信</a></li>
        </ul>
      </div>
    </div>

    <!-- five -->
    <section id="five" class="main style5">
      <div class="container">
        <div class="row gtr-150">
          <div class="col-6 col-12-medium">
            <header class="major">
              <h2>我們的團隊 </h2>
            </header>
            <p>我們的管理人員，均有多年的鑄造經驗，<br>熟悉各個生產步驟，並充分了解客人需要。</p>
            <p>我們的前線工人，均抱有敬業樂業的工作精神，<br>在安全第一的前提下，有效率地生產出品質優良的產品。 </p>
          </div>
        </div>
      </div>
    </section>

    <!-- six -->
    <section id="six" class="spotlight6">
      <div class="image"><img src="../images/4.jpg" alt=""/></div>
      <div class="content">
        <h2>品質監控</h2>
        <br>
        <p>本廠生產的貨品，在來港前均經過嚴格的物料及水壓測試，<br>確保所有供應地盤的物料，均維持統一的優良規格，符合有關部門要求。</p>
      </div>
    </section>
    <section class="spotlight6">
      <div class="image"><img src="../images/5.jpg" alt=""/></div>
      <div class="content">
        <h3>貨期快捷</h3>
        <br>
        <p>為配合香港瞬息萬變的工作節奏，方便客人如期完成工程，<br>本廠大部分常用貨品，己採用全機械化生產，<br>並於香港設有貨倉常備充足現貨，確保從客戶下單一個月內，<br>可以迅速交貨至地盤(訂造產品除外)。</p>
      </div>
    </section>
    <!-- seven -->
    <section id="seven" class="main style7">
      <div class="container">
        <div class="row gtr-150">
        </div>
      </div>
    </section>

    <!-- eight -->

    <section id="eight" class="eight style8">
      <div class="eight container">
        <div class="row gtr-150">
          <div class="col-6 col-12-medium">
            <header class="major">
              <h2>運輸車隊 </h2>
            </header>
            <p>本公司已在港添置數部不同型號的吊臂車，<br>確保無論客戶的地盤位於本港任何地區任何路況，<br>貨物都可以準時送到。</p>
          </div>
          <div class="col-6 col-12-medium imp-medium">
            <span class="eight image fit"><img src="../images/7.jpg" alt=""/></span>
          </div>
        </div>
      </div>
    </section>
    <!-- Footer -->
    <section id="footer" class="main style9">
      <header class="major2">
        <h2>SINCE 1963</h2>
      </header>

    </section>
  </hf-header-layout>
</template>

<script>
import HfHeaderLayout from '../components/hf-header-layout'

export default {
  name: 'about-us',
  components: { HfHeaderLayout },
  data () {
    return {
      bannerImgUrls: [],
      language: sessionStorage.getItem('language')
    }
  },
  async created () {
    let res = await this.$http.get('https://hopfung.com.hk:8443/hop-fung/app/v2/home-page')
    const data = res && res.data && res.data.data
    this.bannerImgUrls = data.bannerImgUrls
  }
}
</script>
<style lang="scss" scoped>

.banner-inner {
  width: 100%;
  position: absolute;
  top: 42%;
  z-index: 10;
  text-align: center;
  font-size: 38px;
}
.banner--image {
  background-position: center 0 !important;
  height: 100% !important;
  width: 100% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 1.5s ease, visibility 1.5s;
}
</style>
