<template>
  <hf-main-layout>
    <div class="page-main">
      <div class="contact">
        <div class="info">
          <div class="header">
            <h3>{{ $t("c1") }}</h3>
          </div>
          <div class="content">
            <div class="item">
              <div class="title">
                <strong
                  ><i class="icon el-icon-map-location"></i
                  >{{ $t("c2") }}</strong
                >
              </div>
              <div class="indent">{{ $t("c3") }}</div>
            </div>
            <div class="item">
              <div class="title">
                <strong
                  ><i class="icon el-icon-phone-outline"></i
                  >{{ $t("c4") }}</strong
                >
              </div>
              <div class="indent">852-24791093, 852-24791016, 852-24767328</div>
            </div>
            <div class="item">
              <div class="title">
                <strong
                  ><i class="icon el-icon-position"></i>{{ $t("c5") }}</strong
                >
              </div>
              <div class="indent">852-24749630</div>
            </div>
            <div class="item">
              <div class="title">
                <strong
                  ><i class="icon el-icon-message"></i>{{ $t("c6") }}</strong
                >
              </div>
              <div class="indent">
                <a href="mailto:manager@hopfung.com.hk" target="_blank"
                  >manager@hopfung.com.hk</a
                >
              </div>
            </div>
            <div class="item">
              <div class="title">
                <strong
                  ><i class="icon el-icon-location-outline"></i
                  >{{ $t("c6_1") }}</strong
                >
              </div>
              <div class="indent">{{ $t("c6_2") }}</div>
            </div>
          </div>
        </div>
        <div class="content">
          <p>
            <font style="font-size: 14px;">
              <span style="font-weight: bold;">{{ $t("c7") }}</span>
            </font>
          </p>
          <p>
            <font style="font-size: 14px;">
              <span style="font-weight: bold;">{{ $t("c4") }}: </span>
              (852) 9858 3235</font
            >
          </p>
          <p style="border-bottom:1px dashed #000; padding-bottom:5px;">
            <font style="font-size: 14px;">
              <span style="font-weight: bold;">{{ $t("c6") }} : </span>
              <a href="mailto:manager@hopfung.com.hk" style="color:#333333"
                >manager@hopfung.com.hk</a
              >
            </font>
          </p>
          <br />
          <p>
            <font style="font-size: 14px;">
              <span style="font-weight: bold;">{{ $t("c8") }}</span>
            </font>
          </p>
          <p>
            <font style="font-size: 14px;">
              <span style="font-weight: bold;">{{ $t("c4") }}: </span>
              (852) 6378 5324</font
            >
          </p>
          <p style="border-bottom:1px dashed #000; padding-bottom:5px;">
            <font style="font-size: 14px;">
              <span style="font-weight: bold;">{{ $t("c6") }} : </span>
              <a href="mailto:thomas.ckm@hopfung.com.hk" style="color:#333333">
                thomas.ckm@hopfung.com.hk
              </a>
            </font>
          </p>
          <br />
          <p>
            <font style="font-size: 14px;">
              <span style="font-weight: bold;">{{ $t("c9") }}</span>
            </font>
          </p>
          <p>
            <font style="font-size: 14px;">
              <span style="font-weight: bold;">{{ $t("c4") }}: </span>
              (852) 6541 1479</font
            >
          </p>
          <p style="border-bottom:1px dashed #000; padding-bottom:5px;">
            <font style="font-size: 14px;">
              <span style="font-weight: bold;">{{ $t("c6") }} : </span>
              <a href="mailto:tomchan@hopfung.com.hk" style="color:#333333">
                tomchan@hopfung.com.hk
              </a>
            </font>
          </p>
          <br />
          <p>
            <font style="font-size: 14px;">
              <span style="font-weight: bold;">{{ $t("c11") }}</span>
            </font>
          </p>
          <p>
            <font style="font-size: 14px;">
              <span style="font-weight: bold;">{{ $t("c4") }}: </span>
              (852) 6704 6912</font
            >
          </p>
          <p style="border-bottom:1px dashed #000; padding-bottom:5px;">
            <font style="font-size: 14px;">
              <span style="font-weight: bold;">{{ $t("c6") }} : </span>
              <a href="mailto:samsonchan@hopfung.com.hk" style="color:#333333">
                samsonchan@hopfung.com.hk
              </a>
            </font>
          </p>
          <br />
        </div>
        <div class="location">
          <h2 class="location--title">{{ $t("c10") }}</h2>
            <div >{{ $t("map") }}<span class="location--txt" v-if="language!=='en'">{{ $t("map2") }}</span></div>
          <img class="location--image" src="../assets/location.png" />
        </div>
      </div>
    </div>
  </hf-main-layout>
</template>

<script>
import HfMainLayout from './components/hf-main-layout'

export default {
  name: 'Contact',
  components: { HfMainLayout },
  props: {},
  data () {
    return {
      language: sessionStorage.getItem('language')
    }
  },
  computed: {},
  watch: {},
  created () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.page-main {
  overflow-y: auto;
  flex: 1;

  .icon:before {
    font-family: element-icons !important;
  }

  .contact {
    height: 100%;
    margin: 0 10%;

    .content {
      margin-top: 15px;
      margin-left: 10px;
      padding: 15px;
      font-weight: 300;
      line-height: 1.571;
      letter-spacing: 0px;

      p {
        margin: 0 0 10px;
      }
    }

    .info {
      flex: 1;
      height: 280px;
      margin: 15px;
      background-color: #eaf1f7;

      .header {
        flex: 1;
        height: 54px;
        background-color: #337ab7;
        line-height: 54px;
        padding-left: 15px;
        color: #fff;
      }

      .content {
        width: 100%;
        margin-top: 20px;
        padding: 15px;
        display: flex;
        flex-direction: row;
        /*justify-content: center;*/
        /*align-items: baseline;*/
        flex-wrap: wrap;

        .indent {
          padding-left: 35px;
          font-size: 12px;
          color: #666;
          line-height: 25px;
        }

        .title {
          font-size: 14px;
          line-height: 30px;
          color: #333;
        }

        .address {
          flex: 0 0 100%;
          margin-bottom: 20px;
        }

        .item {
          height: 100px;
          flex: 0 0 33%;
        }
      }
    }

    .icon {
      width: 35px;
      text-align: center;
      font-size: 16px;
    }

    .location {
      flex: 1;
      margin: 15px;

      &--title {
        height: 45px;
        line-height: 30px;
      }

      &--txt {
        font-size: 22px;
        font-weight: bolder;
      }

      &--image {
        margin-top: 10px;
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
