import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/global.css'
import axios from 'axios'
import ElementUI from 'element-ui'
import '@/styles/theme/index.css'

import i18n from './i18n'
axios.defaults.headers.common['location'] = sessionStorage.getItem('language') === 'en' ? 'en-us' : 'zh-hk'

const client = axios.create()
// 添加响应拦截器
client.interceptors.response.use((response) => {
  // 在接收响应之前做些什么
  // 替换返回结果中的旧地址为新地址
  if (response.data) {
    response.data = recursiveReplace(response.data)
  }
  return response
}, (error) => {
  // 对响应错误做些什么
  return Promise.reject(error)
})
Vue.prototype.$http = client
// Vue.use(iView)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

// 递归替换函数
function recursiveReplace (obj) {
  if (typeof obj === 'string') {
    return obj.replace(/http:\/\/202\.74\.40\.166:8080/g, 'https://hopfung.com.hk:8443')
  } else if (Array.isArray(obj)) {
    return obj.map(item => recursiveReplace(item))
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj = {}
    for (const key in obj) {
      newObj[key] = recursiveReplace(obj[key])
    }
    return newObj
  }
  return obj
}
