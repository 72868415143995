<template>
  <div class="index">
    <castings-manufacturing-process-en v-if="language==='en'"/>
    <castings-manufacturing-process v-else/>
  </div>
</template>

<script>
import CastingsManufacturingProcessEn from './castings-manufacturing-process-en'
import CastingsManufacturingProcess from './castings-manufacturing-process'

export default {
  name: 'Index',
  components: { CastingsManufacturingProcess, CastingsManufacturingProcessEn },
  extends: '',
  mixins: [],
  props: {},
  data () {
    return {
      language: sessionStorage.getItem('language')
    }
  },
  computed: {},
  watch: {},
  filters: {},
  directives: {},
  created () {
    // console.log(sessionStorage.getItem('language'))
    this.language = sessionStorage.getItem('language')
  },
  mounted () {
  },
  destroy () {
  },
  methods: {}
}
</script>

<style lang="scss">
.index.vue {
}
</style>
