import Vue from 'vue'
import Router from 'vue-router'
import Category from './pages/category.vue'
import Product from './pages/product.vue'
import Contact from './pages/contact'
import AboutUs from './pages/about-us'
import pipesManufacturingProcess from './pages/pipes-manufacturing-process'
import castingsManufacturingProcess from './pages/castings-manufacturing-process'
import Index from './pages/index'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/about-us',
      name: 'AboutUs',
      component: AboutUs
    },
    {
      path: '/',
      name: 'Index',
      component: Index
    },
    {
      path: '/pipes-manufacturing-process',
      name: 'PipesManufacturingProcess',
      component: pipesManufacturingProcess
    },
    {
      path: '/castings-manufacturing-process',
      name: 'castingsManufacturingProcess',
      component: castingsManufacturingProcess
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/category',
      name: 'Category',
      component: Category
    },
    {
      path: '/product',
      name: 'Product',
      component: Product
    }
  ]
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

export default router
