<template>
  <hf-header-layout>
    <!-- Banner -->
    <section id="banner">
      <div class="banner-image">
        <div class="banner-inner">
          <h2><a href="castings manufacturing process-e.html">鑄件生產流程</a></h2>
        </div>
      </div>
    </section>

    <!-- nine -->
    <section id="nine" class="wrapper style9 special">
      <header class="major">
        <h2>各款模具 </h2>
      </header>
    </section>

    <div class="spotlights9">
      <div class="spotlight9">
        <header class="major">
          <h2>鋁製模具</h2></header>
        <div class="image alt">
          <img src="../images/c1.jpg" alt=""/>
        </div>
      </div>
      <div class="spotlight9">
        <div class="image fit">
          <img src="../images/c2.jpg" alt=""/>
        </div>
        <div class="content">
          <p>鋁製模具能配合全自動造型機使用，大大縮短生產時間，<br>用機械切割出的鋁模準繩度亦較傳統模具更高，<br>同時由於鋁模能承受更高壓力，生產的鑄造品表面亦較為平滑。</p>
        </div>
      </div>
    </div>

    <!-- ten -->
    <section id="ten">
      <div class="style10">
        <header class="major3">
          <h2>木製模具<br>及發泡膠模具</h2>
        </header>
        <div class="image"><img src="../images/c3.jpg" alt=""/></div>
        <div class="split reversed">
          <div class="content">
            <p>傳統木製模具由我們有多年經驗的師傅全人手製造，<br>可隨客人來圖即時起模，適合生產各種訂製組件，<br>同時亦適用於各種尺寸較大的鑄件。<br><br>為加快生產效率，<br>本廠近年亦開始轉用發泡膠模具，<br>大幅縮減造模時間。
            </p>
          </div>
          <div class="image "><img src="../images/c4.jpg" alt=""/></div>
        </div>
      </div>
    </section>
    <!-- Green Sand Molding -->
    <section id="g11" class="wrapper style11">
      <div class="inner">
        <header class="major">
          <h2>濕砂造型</h2>
        </header>
        <div class="spotlights10">
          <div class="spotlight10">
            <article>
              <a href="#" class="image fit"><img src="../images/c5.jpg" alt=""/></a>
              <div class="content">
                <h2>全自動造型</h2>
                <p>全自動造型機配合鋁製模具，<br>大幅縮短生產時間的同時，<br>亦能令鑄件表面更平滑，<br>提供價廉物美的鑄造產品。 </p>
              </div>
            </article>
          </div>
          <div class="spotlight10">
            <article>
              <a href="#" class="image fit"><img src="../images/c6.jpg" alt=""/></a>
              <div class="content">
                <h2>半自動造型</h2>
                <p>使用半自動造型機配合木製模具，<br>工人先在模具上鋪上鑄造用砂，<br>再用砂箱及機器將砂壓實。<br>由於不受機器尺寸所限，<br>適用於製造井蓋等大型鑄件。</p>
              </div>
            </article>
          </div>
          <div class="spotlight10">
            <article>
              <a href="#" class="image fit"><img src="../images/c7.jpg" alt=""/></a>
              <div class="content">
                <h2>傳統造型</h2>
                <p>傳統沙地造型產量雖不及機械造型，<br>但卻適用於各種大小及形狀的模具，<br>適用於各種特製產品。</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>

    <!-- Casting Process -->
    <section id="style11">
      <div class="content11">
        <h2>鑄造過程</h2>
        <p>我們採用來源可靠的優質原料，<br>每一爐鐵水在倒模前都經過成份分析，確保質量統一。<br><br>我們可按客戶的要求，鑄造出各種生鐵及球鐵產品。<br>多年的鑄造經驗以及優秀的品質，確保我們在行業中的領導地位。
        </p>
      </div>
    </section>
    <!-- eleven -->
    <section id="eleven">

      <article class="thumb">
        <a class="image"><img src="../images/c8.jpg" alt=""/></a>
      </article>

      <article class="thumb">
        <a class="image"><img src="../images/c10.jpg" alt=""/></a>
      </article>
      <article class="thumb">
        <a class="image"><img src="../images/c11.jpg" alt=""/></a>
      </article>
      <article class="thumb">
        <a class="image"><img src="../images/c9.jpg" alt=""/></a>
      </article>
    </section>
    <!-- Footer -->
    <section id="footer" class="main style9">
      <header class="major2">
        <h2>SINCE 1963</h2>
      </header>
    </section>
  </hf-header-layout>
</template>

<script>
import HfHeaderLayout from '../components/hf-header-layout'

export default {
  name: 'castings-manufacturing-process',
  components: { HfHeaderLayout }
}
</script>

<style lang="scss" scoped>
.banner-image {
  width: 100%;
  height: 100%;
  background-image: url("../images/banner2.jpg");
  background-position: center 0;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 1.5s ease, visibility 1.5s;
}

.banner-inner {
  width: 100%;
  height: 80vh;
  position: absolute;
  z-index: 10;
  font-size: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.4);
}
</style>
