<template>
  <div class="hf-header">
    <div class="hf-header--main">
      <div class="hf-header--main__left" >
        <img src="@/assets/hop-Fung-logo.svg" alt  @click="$router.push({path:'/'})"/>
      </div>
      <div class="hf-header--main__right">
        <el-menu :default-active="$route.path" mode="horizontal">
          <el-menu-item index="/" @click.native="$router.push({path:'/'})">{{$t('home')}}</el-menu-item>
          <template v-for="(val,idx) in catList">
            <!--一级路由-->
            <!--没有子类，就直接是product的父类-->
            <el-menu-item
              v-if="!val.children || val.children.length===0"
              :index="`/product?categoryId=${val.id}`"
              @click.native="categoryClick(val.id)"
              :key="val+idx"
            >{{language === 'English'? val.title : val.enTitle}}</el-menu-item>
            <!--有子类，继续循环category-->
            <el-submenu
              v-else
              :key="val+idx"
              @click.native="categoryClick(val.id)"
              :index="`/category?pid=${val.id}`"
            >
              <!--二级路由-->
              <template slot="title">{{language === 'English'? val.title : val.enTitle}}</template>
              <template v-for="(item,idx2) in val.children">
                <!--没有子类，就直接是product的父类-->
                <el-menu-item
                  v-if="!item.children || item.children.length===0"
                  :key="item+idx2"
                  @click.native="productClick(item.id)"
                  :index="`/product?categoryId=${item.id}`"
                >{{language === 'English'? item.title : item.enTitle}}</el-menu-item>
                <!--有子类，继续循环category-->
                <el-submenu
                  v-else
                  :index="`/category?pid=${item.id}`"
                  @click.native="categoryClick(item.id)"
                  :key="item+idx2"
                >
                  <template slot="title">{{language === 'English'? item.title : item.enTitle}}</template>
                  <el-menu-item
                    v-for="(it,idx2) in item.children"
                    :key="it+idx2"
                    @click.native="productClick(it.id)"
                    :index="`/product?categoryId=${it.id}`"
                  >{{language === 'English'? it.title : it.enTitle}}</el-menu-item>
                </el-submenu>
              </template>
            </el-submenu>
          </template>
          <el-submenu index="/about-us">
            <template slot="title">{{$t('about_us')}}</template>
            <el-menu-item
              index="/about-us"
              @click.native="$router.push({path: `/about-us`})"
            >{{$t('our_strengths')}}</el-menu-item>
            <el-menu-item
              index="/castings-manufacturing-process"
              @click.native="$router.push({path: `/castings-manufacturing-process`})"
            >{{$t('ManufacturingProcess')}}</el-menu-item>
            <el-menu-item
              index="/pipes-manufacturing-process"
              @click.native="$router.push({path: `/pipes-manufacturing-process`})"
            >{{$t('pipesManufacturingProcess')}}</el-menu-item>
          </el-submenu>
          <el-menu-item
            index="/contact"
            @click.native="$router.push({path: `/contact`})"
          >{{$t('contactus')}}</el-menu-item>
          <el-menu-item index="/language" @click.native="handleCommand()">{{this.language}}</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HfHeader',
  data () {
    return {
      catList: [],
      language:
        sessionStorage.getItem('language') === 'zh_HK' ? 'English' : '中文'
    }
  },
  async created () {
    // console.log(sessionStorage.getItem('language'))
    const res = await this.$http.get(
      'https://hopfung.com.hk:8443/hop-fung/app/categories-tree?withPicLeaf=false'
    )
    this.catList = res.data.data
  },
  methods: {
    categoryClick (id) {
      this.$router.push({
        path: `/category?pid=${id}`
      })
    },
    productClick (id) {
      // console.log('productClick', id)
      // console.log(this.$router)
      this.$router.push({
        path: `/product?categoryId=${id}`
      })
    },
    handleCommand () {
      // console.log('handleCommand', this.language)
      switch (this.language) {
        case '中文':
          sessionStorage.setItem('language', 'zh_HK')
          window.location.reload()
          break
        case 'English':
          sessionStorage.setItem('language', 'en')
          window.location.reload()
          break
      }
    }
  }
}
</script>

<style lang="scss">
.hf-header {
  font-family: "Anton", "Kumar One", "Noto Serif TC", serif;
  background-color: rgba(0, 0, 0, 0);
  height: 90px;
  display: flex;
  align-items: center;
  z-index: 99999;
  font-size: 16px;
  font-weight: bold !important;
  overflow: hidden;

  &--main {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    height: 90px;
    overflow: hidden;

    &__left {
      padding-left: 2.5em;
      padding-right: 3em;
      padding-top: 1em;
      cursor: pointer;
      img {
        width: 150px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      .el-menu-item {
        text-align: center !important;
        padding-right: 10px !important;
        border-bottom: none !important;
      }
      .el-menu.el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0) !important;
        border-bottom: none !important;
      }
      .el-menu--horizontal > .el-submenu.is-active .el-submenu__title,
      .el-menu--horizontal > .el-menu-item {
        border-bottom: none !important;
      }

      .el-menu--horizontal > .el-menu-item,
      .el-menu--horizontal > .el-submenu .el-submenu__title,
      .el-submenu__title i {
        color: #ffffff !important;
      }

      .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
      .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
      .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
        background-color: rgba(0, 0, 0, 0.3);
        color: #ffffff !important;
      }
    }
  }
  li.el-menu-item,
  .el-submenu__title {
    font-size: 11pt !important;
    font-family: "Anton", "Noto Serif TC", serif !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
  }
}
li.el-menu-item {
  padding: 0 !important;
  padding-left: 10px !important;
  min-width: 60px !important;
  font-size: 16px !important;
}
li.el-submenu {
  padding: 0 !important;
}
li.el-menu-item,
.el-submenu__title {
  font-size: 11pt !important;
}
</style>
