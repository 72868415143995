<template>
  <hf-header-layout>
    <!-- Banner -->
    <section id="banner">
      <div class="banner-image">
        <div class="banner-inner">
          <h2>Pipes Manufacturing Process</h2>
        </div>
      </div>

    </section>

    <!-- Pipes Manufacturing Process -->
    <section id="pipes">
      <div class= "style12">
        <header class="major4">
          <h2>Centrifugal Casting Method  </h2>
        </header>
        <div class="image "><img src="../images/c12.jpg" alt="" /></div>

        <div class="split reversed">
          <div class="content">
            <p>In the centrifugal method, sand-lined moulds are used that are placed horizontally in centrifugal casting machines.<br><br>While the mould revolves, an exact quantity of molten iron is introduced, which, by action of the speed of rotation,<br>distributes itself on the walls of the mould to produce pipe within a few seconds.<br><br>Production rate is dramatically increased.<br><br>This method is suitable for making of BS416, BS437 pipes, and also BS4622 pipe with small internal diameter.  </p>
          </div>
          <div class="image"><img src="../images/c13.jpg" alt="" /></div>
        </div>
      </div>

    </section>
    <!-- Vertical Casting Method -->
    <div class="spotlights9">
      <div class="spotlight9">
        <header class="major">
          <h2>Vertical Casting Method</h2></header>
        <div class="image alt">
          <img src="../images/c15.jpg" alt="" />
        </div>
      </div>
      <div class="spotlight9">
        <div class="image fit">
          <img src="../images/c14.jpg" alt="" />
        </div>
        <div class="content">
          <p>In the vertical casting method, a mould is made by ramming sand around a pattern and drying the mould in an oven. A core is inserted in the mould and molten iron is poured between the core and the mould. By gradually lowering the cooled off section of the casting and consciously pouring melted iron into the mould at the top, a pipe is formed. This method is suitable for making BS4622 pipe with larger internal diameter. </p>
        </div>
      </div>
    </div>

    <!-- Fourteen -->
    <section id="style13">
      <div class="content13">
        <h2>Grinding, painting and finishing</h2>

        <span class="img-2item"><img src="../images/c16.jpg" alt="" /></span>
        <span class="img-2item"><img src="../images/c17.jpg" alt="" /></span>
        <p>To ensure the smooth surface of the pipe,<br>any roughness is then removed by our skilled workers by the use of grinding machines.<br>Bitumen paint is then applied on both the external and internal surface of the pipe.<br>All the paint we use comply with the BS3416 and BS6949 standard.<br>Rubber Gasket, which complies with the BSEN 681 standard is then put inside the pipes. </p>
      </div>
    </section>
    <!-- fifteen -->
    <div class="spotlights1">
      <div class="spotlight1">
        <div class="image fit alt">
          <img src="../images/c18.jpg" alt="" />
        </div>
        <div class="header">
          <h2>Quality Control</h2>

        </div>
      </div>
      <div class="spotlight1">
        <div class="image fit">
          <img src="../images/c19.jpg" alt="" />
        </div>
        <div class="content">
          <p>Before packaging, our pipes have to go through a series of material and hydraulic pressure tests, <br>to make sure they have the same genuine quality and are complied with the relevant standards.</p>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <section id="footer" class="main style9">
      <header class="major2">
        <h2>SINCE 1963</h2>
      </header>

    </section>
  </hf-header-layout>
</template>

<script>
import HfHeaderLayout from '../components/hf-header-layout'
export default {
  name: 'pipes-manufacturing-process-en',
  components: { HfHeaderLayout }
}
</script>

<style lang="scss" scoped>
.banner-image {
  width: 100%;
  height: 100%;
  background-image: url("../images/pipes-1.jpg");
  background-position: center 0;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 1.5s ease, visibility 1.5s;
}

.banner-inner {
  width: 100%;
  height: 80vh;
  position: absolute;
  z-index: 10;
  font-size: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.4);
}
</style>
