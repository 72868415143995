<template>
  <hf-header-layout>
    <!-- Banner -->
    <section id="banner">
      <div class="banner-image">
        <div class="banner-inner">
          <h2>喉管生產流程</h2>
        </div>
      </div>

    </section>

    <!-- Pipes Manufacturing Process -->
    <section id="pipes">
      <div class= "style12">
        <header class="major4">
          <h2>離心鑄造</h2>
        </header>
        <div class="image "><img src="../images/c12.jpg" alt="" /></div>
        <div class="split reversed">
          <div class="content">
            <p>於離心鑄造中，砂模被放置於機器的中心，<br>當機器高速轉動時，處於高溫的鐵漿同時被注入於機器內，<br><br>鐵漿因離心力而平均佈滿於砂模表面，同時因高速轉動而快速冷卻成型。<br><br>此方法可令生產效率大幅提升，<br>適用於BS416及BS437鑄鐵管，以及內徑較小的BS4622鑄鐵管。</p>
          </div>
          <div class="image"><img src="../images/c13.jpg" alt="" /></div>
        </div>
      </div>

    </section>
    <!-- Vertical Casting Method -->
    <div class="spotlights9">
      <div class="spotlight9">
        <header class="major">
          <h2>垂直鑄造</h2></header>
        <div class="image alt">
          <img src="../images/c15.jpg" alt="" />
        </div>
      </div>
      <div class="spotlight9">
        <div class="image fit">
          <img src="../images/c14.jpg" alt="" />
        </div>
        <div class="content">
          <p>在垂直鑄造中，我們先將砂鋪在模具上，<br>加熱烘乾製成砂模，再在砂模中放入模芯，<br><br>當鐵漿注入砂模和模芯的圓形空間中並冷卻後，<br>圓筒狀的筒身便逐漸成型。<br><br>在砂模旋轉的同時注入鐵漿，<br>並將成型的部分緩緩向下拉，即可拉出一條垂直的鑄鐵管。<br>此方法適用於生產尺寸較大的BS4622 生鐵鑄管。 </p>
        </div>
      </div>
    </div>

    <!-- Fourteen -->
    <section id="style13">
      <div class="content13">
        <h2>打磨，上油，及配上膠圈</h2>

        <span class="img-2item"><img src="../images/c16.jpg" alt="" /></span>
        <span class="img-2item"><img src="../images/c17.jpg" alt="" /></span>
        <p>為確保喉管表面及駁口平滑，我們會為每支喉進行撤底的打磨工序，<br><br>之後在喉管表面及內層塗上瀝青油漆防止銹蝕，並在駁口位置配上接駁用的膠圈。<br><br>我們所使用的油漆，均符合BS3416 及BS6949標準，<br><br>我們所使用的橡膠駁口，均符合BSEN 681標準。 </p>
      </div>
    </section>
    <!-- fifteen -->
    <div class="spotlights1">
      <div class="spotlight1">
        <div class="image fit alt">
          <img src="../images/c18.jpg" alt="" />
        </div>
        <div class="header">
          <h2>品質監控</h2>

        </div>
      </div>
      <div class="spotlight1">
        <div class="image fit ">
          <img src="../images/c19.jpg" alt="" />
        </div>
        <div class="content">
          <p>在包裝出貨前，每一支喉管<br>都必先經過水壓及物料測試，<br>確保統一的品質，<br>以及每一支喉都符合相關規格。</p>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <section id="footer" class="main style9">
      <header class="major2">
        <h2>SINCE 1963</h2>
      </header>

    </section>
  </hf-header-layout>
</template>

<script>
import HfHeaderLayout from '../components/hf-header-layout'
export default {
  name: 'pipes-manufacturing-process',
  components: { HfHeaderLayout }
}
</script>

<style lang="scss" scoped>
.banner-image {
  width: 100%;
  height: 100%;
  background-image: url("../images/pipes-1.jpg");
  background-position: center 0;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 1.5s ease, visibility 1.5s;
}

.banner-inner {
  width: 100%;
  height: 80vh;
  position: absolute;
  z-index: 10;
  font-size: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.4);
}
</style>
