<template>
  <hf-main-layout>
    <div class="product">
      <div class="product--left">
        <div class="product--left--title">
          <el-select
            v-model="currentCatCode"
            @change="handleChange"
            v-if="productList"
          >
            <el-option
              v-for="item in productList"
              :key="item.code"
              :label="item.title"
              :value="item.code"
            ></el-option>
          </el-select>
        </div>
        <div class="product--left--image" style="cursor: pointer">
          <el-carousel>
            <el-carousel-item
              v-for="(item, index) in currentProd.imgUrls"
              :key="`currentProd${item}`"
            >
              <div
                style="width: 100%;height: 100%;display: flex;justify-content:center;align-items: center"
              >
                <img
                  :src="item"
                  :alt="item"
                  class="product--left--image--item"
                  @load="imageLoad($event)"
                  @click="handlerImageClick(index, currentProd.imgUrls)"
                />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="display: block;margin-top: 10px;margin-bottom: 10px">
          <div class="intro-title">{{ $t("intro") }}</div>
          <p class="intro-txt">{{ currentProd.introduction }}</p>
        </div>
        <a
          style="display: block;margin-top: 10px;margin-bottom: 10px"
          v-for="(file, index) in category.attachmentVos"
          :key="index"
          :href="file.attachmentPathUrl"
          :download="file.fileName"
          >{{ file.fileName }}</a
        >
        <div
          v-if="
            currentProd.attachmentVos && currentProd.attachmentVos.length > 0
          "
        >
          <div class="att_h4">{{ $t("att_1") }}</div>
          <a
            style="display: block;margin-bottom: 10px"
            target="_blank"
            v-for="(file, index) in currentProd.attachmentVos"
            :key="index"
            :href="file.attachmentPathUrl"
            :download="file.fileName"
            >{{ file.fileName }}</a
          >
        </div>
      </div>

      <div class="product--right">
        <div class="plzSelect">{{ $t("plzSelect") }}</div>
        <div v-if="filterList.length > 0">
          <div
            class="product--right--title"
            v-for="(filter, index) in filterList"
            :key="filter + index"
          >
            <div style="width: 100%;line-height: 1.5;padding-bottom: 2px">
              {{ filter.label }}
            </div>
            <el-select
              v-model="filter.paramVal"
              @change="handleProdChange($event, filter, index)"
              :placeholder="$t('select')"
            >
              <el-option
                v-for="(prod, idx) in filter.list"
                :key="prod + idx"
                :label="prod.paramValue"
                :value="prod.paramValue"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div v-if="detail && detailJson && detailJson.length > 0">
          <h3>{{ detail.itemName }}</h3>
          <div class="att_h4">{{ $t("parameters") }}</div>
          <div
            class="product--right--detail"
            v-for="(key, index) in detailJson"
            :key="index"
          >
            <div class="product--right--detail__key">{{ key.paramKey }}:</div>
            <div class="product--right--detail__value">
              {{ key.paramValue }}
            </div>
          </div>
          <div
            style="width: 100%;border-bottom: 1px solid rgba(0, 0, 0, 0.6);"
          ></div>
        </div>

        <div v-if="attachmentVos && attachmentVos.length > 0">
          <div class="att_h4">{{ $t("att_2") }}</div>
          <a
            style="display: block;margin-bottom: 10px"
            target="_blank"
            v-for="(file, index) in attachmentVos"
            :key="index"
            :href="file.attachmentPathUrl"
            :download="file.fileName"
            >{{ file.fileName }}</a
          >
        </div>
      </div>
      <div v-if="showImage" class="show-image">
        <img :src="currentImg" alt class="show-image--img" />
        <div class="show-image--button" @click="showImage = false">
          <i class="el-icon-close" />
        </div>
      </div>
    </div>
  </hf-main-layout>
</template>

<script>
import HfMainLayout from './components/hf-main-layout'

export default {
  name: 'Product',
  components: { HfMainLayout },
  data () {
    return {
      pId: '',
      categoryList: [],
      currentCatCode: '',
      currentCat: {},
      productList: [],
      currentProdId: '',
      currentProd: {},
      filterVos: [],
      detail: {},
      detailJson: [],
      category: {},
      attachmentVos: [],
      showImage: false,
      currentImg: '',
      filterList: []
    }
  },
  watch: {
    $route: {
      async handler (to, from) {
        if (to.query.categoryId) {
          this.categoryList = []
          this.currentCatCode = ''
          this.currentCat = {}
          this.productList = []
          this.currentProdId = ''
          this.currentProd = {}
          this.filterVos = []
          this.detail = {}
          this.detailJson = []
          this.pId = to.query.categoryId
        }
        await this.initData()
      },
      deep: true,
      immediate: true
    },
    filterVos: {
      handler (val) {
        this.getDetail()
      },
      deep: true
    },
    currentCatCode (val) {
      this.currentProd = {}
      this.filterVos = []
      this.detail = {}
      this.detailJson = []
      this.handleChange(val)
    }
  },
  async created () {
    // await this.initData()
  },
  methods: {
    async initData () {
      const res = await this.$http.get(
        `https://hopfung.com.hk:8443/hop-fung/app/categories/${this.pId}`
      )
      this.category = res && res.data && res.data.data
      await this.getProductList()
    },
    async handleChange (val) {
      this.currentProd = this.productList.find(item => item.code === val)
      this.filterVos = [...this.currentProd.filterVos]
      this.filterList = []
      this.putData2FilterList(this.filterVos)
    },

    putData2FilterList (filterVos, idx = 0) {
      // console.log('putData2FilterList', filterVos)
      if (filterVos && filterVos.length > 0) {
        const item = filterVos[0]
        const obj = {
          ...item,
          label: item.paramKey,
          list: [...filterVos]
        }
        this.filterList[idx] = obj
        this.filterList = JSON.parse(JSON.stringify(this.filterList))
        if (item.children && item.children.length > 0) {
          this.putData2FilterList(item.children, idx + 1)
        }
      }
    },
    async getProductList () {
      const res = await this.$http.get(
        `https://hopfung.com.hk:8443/hop-fung/app/v2/products?categoryId=${this.pId}`
      )
      this.productList = res.data.data
      // console.log('getProductList', this.productList)
      if (
        this.productList &&
        this.productList.length &&
        this.productList.length > 0
      ) {
        this.currentProd = this.productList[0]
        this.currentCatCode = this.currentProd.code
      }
    },
    handleProdChange (val, filter, idx) {
      // console.log('handleProdChange', val, filter, idx)
      const one =
        filter.list && filter.list.find(item => item.paramValue === val)
      // console.log('handleProdChange', one)
      this.detailJson = []
      if (one && one.children && one.children.length > 0) {
        this.putData2FilterList(one.children, idx + 1)
      } else {
        this.getDetail()
      }
    },
    getDetail () {
      // console.log('getDetail', this.currentProd.detailVos)
      let res = [...this.currentProd.detailVos]
      for (let filter of this.filterList) {
        res = res.filter(item => {
          const json = JSON.parse(item.paramsDetailJson)
          for (let it of json) {
            if (
              it.paramKey === filter.paramKey &&
              it.paramValue === filter.paramVal
            ) {
              return true
            }
          }
        })
      }
      this.detail = null
      this.detailJson = null
      if (res.length > 0) {
        this.detail = res[0]
        this.detailJson = JSON.parse(res[0].paramsDetailJson)
        this.attachmentVos = res[0].attachmentVos
      }
    },
    handlerImageClick (index, list) {
      this.currentImg = list[index]
      this.showImage = true
    },
    imageLoad (e) {
      // console.log(e)
    }
  }
}
</script>

<style lang="scss">
.product {
  .intro-txt {
    text-indent: 1em;
    font-size: 16px;
    line-height: 1.5;
    color: #444;
    word-break: break-all;
  }
  .intro-title {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
  }
  .att_h4 {
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 1.1em;
    line-height: 1.5em;
  }
  .plzSelect {
    padding: 0 0 30px 0;
    font-size: 1.5em;
    font-weight: 600;
  }
  padding-bottom: 25px;
  background-color: #ffffff;
  margin-left: 6%;
  margin-right: 6%;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  &--left {
    flex: 1;

    &--title {
      height: 40px;
    }

    &--image {
      width: 100%;
      margin-top: 20px;
      height: 500px;
      overflow: hidden;
      border: 1px solid #dcdfe6;
      .el-carousel__container {
        height: 500px;
      }
      &--item {
        text-align: center;
        height: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  &--right {
    margin-left: 5%;
    flex: 1;

    &--title {
      /*align-items: center;*/
      /*display: flex;*/
      /*flex-direction: row;*/
      height: 50px;
      margin-bottom: 30px;
    }

    &--detail {
      display: flex;
      flex-direction: row;
      line-height: 35px;
      width: 500px;
      border-top: 1px solid rgba(0, 0, 0, 0.6);
      border-left: 1px solid rgba(0, 0, 0, 0.6);
      border-right: 1px solid rgba(0, 0, 0, 0.6);
      span {
        display: inline-block;
      }

      &__key {
        padding: 4px 8px;
        width: 250px;
        border-right: 1px solid rgba(0, 0, 0, 0.6);
      }

      &__value {
        padding: 4px 8px;
        flex: 1;
      }
    }
  }

  .el-select {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-color: rgba(0, 0, 0, 0.1) !important;
  }
}

.show-image {
  top: 0;
  bottom: 0px;
  padding: 15px;
  text-align: center;
  overflow: auto;
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  &--img {
    width: 800px;
    height: auto;
  }
  &--button {
    font-size: 25px;
    font-weight: 800;
    color: #ffffff;
    position: absolute;
    z-index: 10000;
    right: 50px;
    top: 25px;
    cursor: pointer;
  }
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: red;
    font-size: 20px;
    font-weight: bolder;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: red;
    font-size: 20px;
    font-weight: bolder;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: red;
    font-size: 20px;
    font-weight: bolder;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
    font-size: 20px;
    font-weight: bolder;
  }
}
</style>
