<template>
  <hf-header-layout>
    <!-- Banner -->
    <section id="banner">
      <el-carousel height="80vh" :interval="9000">
        <el-carousel-item
          v-for="(image, index) in bannerImgUrls"
          :key="`image_${index}`"
        >
          <div
            :style="{ background: `url(${image})` }"
            class="banner--image"
          />
        </el-carousel-item>
      </el-carousel>
      <div class="banner-inner" :class="language === 'en' ? '' : 'banner-ch'">
        <h2>
          {{ $t("HopFungIronWorksLtd") }}
          <br />
          {{ $t("Experienced") }}
        </h2>
      </div>
    </section>

    <!-- Main -->
    <div id="main">
      <center>
        <a
          class="download-link"
          href="https://u.pcloud.link/publink/show?code=kZ41rAkZwmbAfAJWgWmMJhnozl1nShXyPfLV"
          >{{ $t("DownloadLink") }}<i class="el-icon-download"
        /></a>
        <h2 style="margin-top: 10px;">{{ $t("ProductShow") }}</h2>
      </center>
      <!-- web -->
      <section class="tiles">
        <article
          class="style1"
          v-for="(image, index) in homePageV2Vos"
          :key="'pro' + index"
        >
          <span class="image">
            <img :src="image.imgUrls[0]" alt />
          </span>
          <a @click="toUrl(image)" style="cursor: pointer;">
            <div
              style="background-color:#9F9F9F"
              v-for="(txt, index) in image.titles"
              :key="txt + index"
            >
              {{ txt }}
            </div>
          </a>
        </article>
      </section>
      <!-- app -->
    </div>

    <!-- Footer -->
    <footer id="footer">
      <div class="copyright">
        <p>
          &copy; Hop Fung Iron Works Ltd. All rights reserved. Tel: +852 2476
          7328 Email:
          <a href="mailto:manager@hopfung.com.hk" target="_blank"
            >manager@hopfung.com.hk</a
          >
        </p>
      </div>
    </footer>
  </hf-header-layout>
</template>

<script>
import HfHeaderLayout from './components/hf-header-layout'
export default {
  name: 'index.vue',
  components: { HfHeaderLayout },
  data () {
    return {
      menu: [],
      bannerImgUrls: [],
      homePageV2Vos: [],
      tabshow: false,
      language: sessionStorage.getItem('language')
    }
  },
  async created () {
    let res = await this.$http.get(
      'https://hopfung.com.hk:8443/hop-fung/app/v2/home-page'
    )
    const data = res && res.data && res.data.data
    this.bannerImgUrls = data.bannerImgUrls
    this.homePageV2Vos = data.homePageV2Vos.map(item => {
      // console.log(item)
      return {
        ...item,
        titles: item.title.split('<br>' || '<br/>')
      }
    })
    // console.log(this.homePageV2Vos)
    const res2 = await this.$http.get(
      'https://hopfung.com.hk:8443/hop-fung/app/categories-tree?withPicLeaf=false'
    )
    this.menu = res2.data.data
    // console.log('res2', this.menu, this.bannerImgUrls)
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
    // 监听窗口大小
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  methods: {
    handleScroll () {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      // console.log(scrollTop)
      if (scrollTop > 510) {
        this.tabshow = true
      } else if (scrollTop < 510) {
        this.tabshow = false
      }
    },
    toUrl (item) {
      window.location.href = item.url
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-inner {
  width: 100%;
  position: absolute;
  top: 42%;
  z-index: 10;
  text-align: center;
  font-size: 38px;
}
.banner--image {
  background-position: center 0 !important;
  height: 100% !important;
  width: 100% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 1.5s ease, visibility 1.5s;
}
.banner-app {
  position: relative;
  height: 30vh;
  &--image {
    background-position: center 0 !important;
    height: 100% !important;
    width: 100% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    transition: opacity 1.5s ease, visibility 1.5s;
  }
}
.download-link {
  background-color: red;
  color: #fff;
  padding: 4px;
}
.secton-app {
  display: flex;
  flex-flow: row wrap;
  &__item {
    position: relative;
    width: 50%;
    padding: 15px;
  }
  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
}
</style>
