<template>
  <hf-header-layout>
    <section id="banner">
       <el-carousel height="80vh" :interval="9000">
        <el-carousel-item v-for="(image,index) in bannerImgUrls" :key='`image_${index}`'>
          <div :style="{background: 'url(' + image + ')'}" class="banner--image" />
        </el-carousel-item>
      </el-carousel>
      <div class="banner-inner">
        <h2>Hop Fung Iron Works Ltd
          <br>Experienced&nbsp;&#8226;&nbsp;Genuine Quality&nbsp;&#8226;&nbsp;World Standard</h2>
      </div>
    </section>
    <!-- One -->
    <section id="one" class="wrapper style1">
      <div class="container">
        <header class="major">
          <h2>Decades of Experience</h2>
          <p>Hop Fung Iron Works Ltd was founded in 1963&#44;<br>located at Ping Shan&#44;
            Yuen Long&#44;&nbsp;specialized in cast iron industry&#46;</p>
        </header>
        <div class="slider">
          <div class="viewer">
            <div class="slide">
              <img src="../images/de1.jpg" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Two -->
    <div class="spotlights1">
      <div class="spotlight1">
        <div class="image fit">
          <img src="../images/2.jpg" alt=""/>
        </div>
        <div class="content">
          <p>Nowadays&#44;&nbsp;with decades of experience and professional manufacturing technique&#44; </p>
        </div>
      </div>
      <div class="spotlight1">
        <div class="image fit alt">
          <img src="../images/3.jpg" alt=""/>
        </div>
        <div class="content">
          <p>we are supplying our best quality cast iron drainage materials to all the major infrastructure projects in
            Hong Kong&#46;</p>
        </div>
      </div>
    </div>

    <!-- Three -->
    <div class="wrapper style2 ">
      <div class="inner">
        <header class="major">
          <h2>Quality Product</h2>
          <p>All our company&#39;s products are manufactured under the standard of ISO 9001:2015.
            <br/><br>All the cast iron materials manufactured by our foundry<br>are tested by certified laboratory in
            Hong Kong to comply with the BSEN1452 and BSEN 1561 standard&#46;<br/><br>
            Our ductile iron material is also tested to comply with BSEN 1563 standard&#46;<br/>Therefore&#44; all of
            our products are proofed to be 100% government&#45;requirement&#45;reaching&#46;</p>
        </header>
        <span class="img-3item"><img src="../images/1452.png" alt=""/></span>
        <span class="img-3item"><img src="../images/1561.png" alt=""/></span>
        <span class="img-3item"><img src="../images/1563.png" alt=""/></span>
        <ul class="actions special">
          <li><a href="https://drive.google.com/drive/folders/1at9OpMp-PkPvBBo7Gt0uToMhZWos3Q_h" target="_blank" class="button large">Download Our Certificates</a></li>
        </ul>
      </div>
    </div>

    <!-- four -->
    <div class="wrapper style3 ">
      <div class="inner">
        <header class="major">
          <h2>Job Reference</h2>
          <p>Ever since established&#44; in the last half century&#44;<br/> our product has been supplied to numerous
            major infrastructure projects&#44; <br/>
            and are proudly favored by many construction and engineering company.<br/> <br>Today&#44; our casting can be
            found in various landmark in Hong Kong&#44; <br/>such as the Hong Kong International Airport&#44; the Hong
            Kong Disneyland&#44; etc&#46;<br/></p>
        </header>
        <span class="img-2item"><img src="../images/job1.png" alt=""/></span>
        <span class="img-2item"><img src="../images/job2.png" alt=""/></span>
        <ul class="actions special">
          <li><a target="_blank"
                 href="https://drive.google.com/drive/folders/1dTRu-5b2A_qRzRiEQAmL26YBNClMma1I" class="button large">Download Our Job Reference</a></li>
        </ul>
      </div>
    </div>

    <!-- five -->
    <section id="five" class="main style5">
      <div class="container">
        <div class="row gtr-150">
          <div class="col-6 col-12-medium">
            <header class="major">
              <h2>Our Team</h2>
            </header>
            <p>Our management team have years of experience in the casting industry <br>and are known to be familiar
              with every production steps<br>and our clients’ needs.</p>
            <p>Our frontline workers embrace the spirit of professionalism.<br><br>With the mortal of “safety first” in
              mind,<br>we strive to provide you with the best quality products<br>with the highest efficiency. </p>
          </div>
        </div>
      </div>
    </section>

    <!-- six -->
    <section id="six" class="spotlight6">
      <div class="image"><img src="../images/4.jpg" alt=""/></div>
      <div class="content">
        <h2>Quality Control</h2>
        <p>Before shipping to Hong Kong, our products go through a series of material and hydraulic pressure tests, to
          make sure they have the same genuine quality and are comply with the relevant standards.</p>
      </div>
    </section>
    <section class="spotlight6">
      <div class="image"><img src="../images/5.jpg" alt=""/></div>
      <div class="content">
        <h3>Fastest Turn-around</h3>
        <p>In order for our clients to complete the construction work on time, we have mechanicalized most of our
          production process, and have abundant amount of stocks in Hong Kong. We promise to deliver the ordered items
          to our clients within one month after official order (except customized items).
        </p>
      </div>
    </section>
    <!-- seven -->
    <section id="seven" class="main style7">
      <div class="container">
        <div class="row gtr-150">
        </div>
      </div>
    </section>

    <!-- eight -->

    <section id="eight" class="eight style8">
      <div class="eight container">
        <div class="row gtr-150">
          <div class="col-6 col-12-medium">
            <header class="major">
              <h2>Delivery Team</h2>
            </header>
            <p>Different models of craned vehicles<br>are standing by in Hong Kong,making sure the ordered items can be
              delivered to our clients on time, despite the location and road conditions.</p>
          </div>
          <div class="col-6 col-12-medium imp-medium">
            <span class="eight image fit"><img src="../images/7.jpg" alt=""/></span>
          </div>
        </div>
      </div>
    </section>
    <!-- Footer -->
    <section id="footer" class="main style9">
      <header class="major2">
        <h2>SINCE 1963</h2>
      </header>

    </section>
  </hf-header-layout>
</template>

<script>
import HfHeaderLayout from '../components/hf-header-layout'

export default {
  name: 'about-us-en',
  components: { HfHeaderLayout },
  data () {
    return {
      bannerImgUrls: []
    }
  },
  async created () {
    let res = await this.$http.get('https://hopfung.com.hk:8443/hop-fung/app/v2/home-page')
    const data = res && res.data && res.data.data
    this.bannerImgUrls = data.bannerImgUrls
  }
}
</script>
<style lang="scss" scoped>

.banner-inner {
  width: 100%;
  position: absolute;
  top: 42%;
  z-index: 10;
  text-align: center;
  font-size: 38px;
}
.banner--image {
  background-position: center 0 !important;
  height: 100% !important;
  width: 100% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 1.5s ease, visibility 1.5s;
}
</style>
