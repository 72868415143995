<template>
  <div class="index">
    <about-us-en v-if="language==='en'"/>
    <about-us v-else/>
  </div>
</template>

<script>
import AboutUs from './about-us'
import AboutUsEn from './about-us-en'

export default {
  name: 'Index',
  extends: '',
  mixins: [],
  components: { AboutUsEn, AboutUs },
  props: {},
  data () {
    return {
      language: sessionStorage.getItem('language')
    }
  },
  computed: {},
  watch: {},
  filters: {},
  directives: {},
  created () {
    // console.log(sessionStorage.getItem('language'))
    this.language = sessionStorage.getItem('language')
  },
  mounted () {
  },
  destroy () {
  },
  methods: {}
}
</script>

<style lang="scss">
.index.vue {
}
</style>
