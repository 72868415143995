export default {
  home: 'Home',
  about_us: 'About Us',
  our_strengths: 'Company Profile & Certification',
  ManufacturingProcess: 'Castings Manufacturing Process',
  pipesManufacturingProcess: 'Pipes Manufacturing Process',
  contactus: 'Contact Us',
  HopFungIronWorksLtd: 'Hop Fung Iron Works Ltd',
  DownloadLink: 'Catalogue, Company Profile, and Job Referene',
  ProductShow: 'PRODUCT SHOW',
  Experienced: '"Experienced • Genuine Quality • World Standard"',
  DecadesofExperience: 'Decades of Experience',
  about1: 'Hop Fung Iron Works Ltd was founded in 1963¸located at Ping Shan, Yuen Long, specialized in cast iron industry.',
  about2: 'Nowadays, with decades of experience and professional manufacturing technique, we are supplying our best quality cast iron drainage materials to all the major infrastructure projects in Hong Kong.',
  about3: 'Quality Product',
  about4: 'All our company’s products are manufactured under the ISO 9001:2015 standard.',
  about5: 'All the cast iron materials manufactured by our foundry are tested by certified laboratory in Hong Kong to comply with the BSEN1452 and BSEN 1561 standard. Our ductile iron material is also tested to comply with BSEN 1563 standard. Therefore, all of our products are proofed to be 100% government-requirement-reaching. To download our certificates, please click',
  about5_1: 'here',
  about5_2: '.',
  about5_3: 'Job Reference',
  about5_4: 'Ever since established, in the last half century, our product has been supplied to numerous major infrastructure projects, and are proudly favored by many constructions and engineering company. Today, our casting can be found in various landmark in Hong Kong, such as the Hong Kong International Airport, the Hong Kong Disneyland, etc.',
  about5_5: 'You can download job reference and approval letter from our previous projects by clicking ',
  about5_6: 'here',
  about5_7: '.',
  about6: 'Our Team',
  about7: 'Our management team have years of experience in the casting industry, and are known to be familiar with every production steps and our clients’ needs.',
  about8: 'Our frontline workers embrace the spirit of professionalism. With the mortal of “safety first” in mind, we strive to provide you the best quality products with the highest efficiency.',
  about9: 'Quality Control',
  certificates: 'Check the certificates and supporting documents by click here',
  about10: 'Before shipping to Hong Kong, our products go through series of material and hydraulic pressure tests, to make sure they have the same genuine quality and are comply with the relevant standards.',
  about11: 'Fastest Turn-around In order for our clients to complete the construction work on time, we have mechanicalized  most of our production process, and have abundant amount of stocks in Hong Kong. We promise to deliver the ordered items to our clients within one month after official order (except customized items).',
  about12: 'Delivery Team',
  about13: 'Different models of craned vehicles are standing by in Hong Kong, making sure the ordered items can be delivered to our clients on time, despite the location and road conditions.',
  about14: 'Since 1963',
  about15: 'Fastest Turn-around',
  about16: 'In order for our clients to complete the construction work on time, we have mechanicalized most of our production process, and have abundant amount of stocks in Hong Kong. We promise to deliver the ordered items to our clients within one month after official order (except customized items).',
  mf1: 'Various Types Of Pattern',
  mf2: 'Aluminum',
  mf3: 'Aluminum pattern is used with fully automated sand molding machine, productivity is therefore drastically increased. Finish products with smoother surface and more accurate dimensions are also achieved.  ',
  mf4: 'Traditional wood pattern and Styrofoam pattern',
  mf5: 'Traditional wood pattern are made by our experienced, finely skilled craftsmen by hand. With high flexibility, this method is suitable for making specially ordered items. In order to achieve fastest turnaround in the industry, we have gradually switched to Styrofoam patterning recently. Time for pattern-crafting is drastically shortened.',
  mf6: 'Green Sand Molding',
  mf7: 'Fully automated molding',
  mf8: 'With automated sand molding machine and aluminum molding pattern, production time is drastically shortened, providing cast iron material to our customers with better quality and more competitive prices. ',
  mf9: 'Manual molding',
  mf10: 'Manual molding is usegether with wood pattern. The wet sand is lay on top of the pattern by our workers, who than consolidate it with sand box and machine. Since the size of pattern is not limited by the machine, this method is suitable for casting larger items like manhole covers.',
  mf11: 'Traditional molding',
  mf12: 'Although traditional molding is not as efficient as automated molding, it can be used for pattern of different shapes and sizes, which makes it ideal for casting tailor-made items.',
  mf13: 'Casting Process',
  mf14: 'We import high quality raw materials from reliable sources for our manufacturing process. Before pouring into the sand mold, the melted iron has to go through series of component analysis, in order to achieve consistent quality.',
  mf15: 'Based on our clients’ request, we can cast various cast iron and ductile iron products. With 50 years of experience and astonishing quality, we have been the leading cooperation in our industry for decades. ',
  c1: 'Contact Us',
  c2: 'Address',
  c3: '168 Castle Peak Rd - Mai Po, N.T.',
  c4: 'Tel',
  c5: 'Fax',
  c6: 'Email',
  c6_1: 'Mailing Address',
  c6_2: 'P.O. Box No.1385. Yuen Long Post Office, N. T., Hong Kong',
  c7: 'Wing Chan',
  c8: 'Thomas Chan',
  c9: 'Tom Chan',
  c10: 'Location',
  c11: 'Samson Chan',
  parameters: 'parameters',
  attachment: 'attachment',
  att_1: 'Product Catalog',
  att_2: 'Product Certification and Supporting Documents',
  intro: 'Introduction',
  select: 'Please Select',
  isApp: 'Phone browsing is currently not supported, please visit us through computer, thank you.',
  plzSelect: 'Please select criteria according to your needs',
  // 适配app端调整
  options: 'opt',
  map: 'Please search for 168 Castle Peak Rd.'
}
