<template>
  <div class="hf-main-layout">
    <div style="background-color: #CCCCCC">
      <hf-header-gray class="wb-display"></hf-header-gray>
    </div>
    <div class="hf-main-layout-main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import HfHeaderGray from './hf-header-gray'

export default {
  name: 'HfMainLayout',
  extends: '',
  mixins: [],
  components: { HfHeaderGray },
  props: {},
  data () {
    return {
      isApp: false,
      screenWidth: document.body.clientWidth // 屏幕宽度
    }
  },
  computed: {},
  watch: {
    screenWidth: {
      handler (val) {
        this.isApp = val < 768
      },
      immediate: true
    }
  },
  filters: {},
  directives: {},
  created () {},
  mounted () {
    // 监听窗口大小
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  destroy () {},
  methods: {}
}
</script>

<style lang="scss">
.hf-main-layout {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .hf-main-layout-main {
    width: 100%;
    flex: 1;
    overflow-y: auto;
  }
  .wb-display {
    display: block;
  }
  .h5-display {
    display: none;
  }
}
</style>
