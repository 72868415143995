<template>
  <hf-header-layout>
    <!-- Banner -->
    <section id="banner">
      <div class="banner-image">
        <div class="banner-inner">
          <h2><a href="castings manufacturing process-e.html">Castings Manufacturing Process</a></h2>
        </div>
      </div>
    </section>

    <!-- nine -->
    <section id="nine" class="wrapper style9 special">
      <header class="major">
        <h2>Various Types Of Pattern</h2>
      </header>
    </section>

    <div class="spotlights9">
      <div class="spotlight9">
        <header class="major">
          <h2>Aluminum Pattern</h2></header>
        <div class="image alt">
          <img src="../images/c1.jpg" alt="" />
        </div>
      </div>
      <div class="spotlight9">
        <div class="image fit">
          <img src="../images/c2.jpg" alt="" />
        </div>
        <div class="content">
          <p>The aluminum pattern<br>is used with fully automated sand molding machine,<br>productivity is therefore drastically increased.<br>Finish products with smoother surface and more accurate dimensions are also achieved.   </p>
        </div>
      </div>
    </div>

    <!-- ten -->
    <section id="ten">
      <div class= "style10">
        <header class="major3">
          <h2>Traditional wood pattern<br>and Styrofoam pattern </h2>
        </header>
        <div class="image" ><img src="../images/c3.jpg" alt="" /></div>
        <div class="split reversed">
          <div class="content">
            <p>The traditional wood pattern<br>is made by our experienced,<br>finely skilled craftsmen by hand.<br><br>
              With high flexibility,<br>this method is suitable for making specially ordered items. <br><br>
              In order to achieve fastest turnaround in the industry, we have gradually switched to Styrofoam patterning recently.<br><br>Time for pattern-crafting is drastically shortened. </p>
          </div>
          <div class="image "><img src="../images/c4.jpg" alt="" /></div>
        </div>
      </div>
    </section>
    <!-- Green Sand Molding -->
    <section id="g11" class="wrapper style11">
      <div class="inner">
        <header class="major">
          <h2>Green Sand Molding</h2>
        </header>
        <div class="spotlights10">
          <div class="spotlight10 w-440">
            <article>
              <a href="#" class="image fit"><img src="../images/c5.jpg" alt="" /></a>
              <div class="content">
                <h2>Fully automated molding</h2>
                <p>With automated sand molding machine <br> aluminum molding pattern,<br>production time is drastically shortened,<br>providing cast iron material to our customers<br>with better quality and more competitive prices.  </p>
              </div>
            </article>
          </div>
          <div class="spotlight10 w-440">
            <article>
              <a href="#" class="image fit"><img src="../images/c6.jpg" alt="" /></a>
              <div class="content">
                <h2>Manual molding</h2>
                <p>Manual molding is usegether with a wood pattern.<br>The wet sand is laid on top of the pattern by our workers,<br>who than consolidate it with sand box and machine.<br>Since the size of pattern is not limited by the machine,<br>this method is suitable for casting larger items<br>like manhole covers.</p>
              </div>
            </article>
          </div>
          <div class="spotlight10 w-440">
            <article>
              <a href="#" class="image fit"><img src="../images/c7.jpg" alt="" /></a>
              <div class="content">
                <h2>Traditional molding</h2>
                <p>Although traditional molding<br>is not as efficient as automated molding,<br>it can be used for the pattern of different shapes and sizes,<br>which makes it ideal for casting tailor-made items.</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>

    <!-- Casting Process -->
    <section id="style11">
      <div class="content11">
        <h2>Casting Process</h2>
        <p>We import high quality raw materials from reliable sources for our manufacturing process.<br>Before pouring into the sand mold, the melted iron has to go through a series of component analysis,<br>in order to achieve consistent quality.<br><br>Based on our clients’ request, we can cast various cast iron and ductile iron products.<br>With 50 years of experience and astonishing quality, we have been the leading cooperation in our industry for decades.</p>
      </div>
    </section>
    <!-- eleven -->
    <section id="eleven">
      <article class="thumb">
        <a class="image"><img src="../images/c8.jpg" alt="" /></a>
      </article>
      <article class="thumb">
        <a class="image"><img src="../images/c10.jpg" alt="" /></a>
      </article>
      <article class="thumb">
        <a class="image"><img src="../images/c11.jpg" alt="" /></a>
      </article>
      <article class="thumb">
        <a class="image"><img src="../images/c9.jpg" alt="" /></a>
      </article>

    </section>
    <!-- Footer -->
    <section id="footer" class="main style9">
      <header class="major2">
        <h2>SINCE 1963</h2>
      </header>

    </section>
  </hf-header-layout>
</template>

<script>
import HfHeaderLayout from '../components/hf-header-layout'

export default {
  name: 'castings-manufacturing-process-en',
  components: { HfHeaderLayout }
}
</script>

<style lang="scss" scoped>
.banner-image {
  width: 100%;
  height: 100%;
  background-image: url("../images/banner2.jpg");
  background-position: center 0;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 1.5s ease, visibility 1.5s;
}

.banner-inner {
  width: 100%;
  height: 80vh;
  position: absolute;
  z-index: 10;
  font-size: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.4);
}
</style>
