<template>
  <div class="hf-header-layout" id="scrollEl">
    <section class="hf-header" v-show="!tabshow">
      <hf-header class="wb-display" />
    </section>
    <transition name="fade">
      <section class="hf-header-gray" v-show="tabshow">
        <hf-header-gray class="wb-display" />
      </section>
    </transition>
    <slot />
  </div>
</template>

<script>
import HfHeader from './hf-header'
import HfHeaderGray from './hf-header-gray'

export default {
  name: 'hf-header-layout',
  components: { HfHeaderGray, HfHeader },
  data () {
    return {
      tabshow: false
    }
  },
  mounted () {
    window.addEventListener('scroll',
      this.handleScroll,
      true
    )
  },
  methods: {
    handleScroll () {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      const pointHeight = 510
      // console.log('handleScroll', { scrollTop })
      if (scrollTop > pointHeight) {
        this.tabshow = true
      } else if (scrollTop < pointHeight) {
        this.tabshow = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hf-header-layout {
  .hf-header {
    position: absolute;
    z-index: 20;
    top: 0;
    width: 100%;
    min-width: 1366px;
  }
  .wb-display {
    display: block;
    min-width: 1366px;
  }
  .h5-display {
    display: none;
  }

  .hf-header-gray {
    background-color: #cccccc;
    position: fixed;
    z-index: 20;
    top: 0;
    width: 100%;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s cubic-bezier(0.2, 1, 0.22, 1);
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
