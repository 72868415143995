<template>
  <div id="app">
    <div class="app-main">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  name: 'app',
  data () {
    return {
      screenWidth: document.body.clientWidth // 屏幕宽度
    }
  },
  mounted () {
    // 监听窗口大小
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  watch: {
    screenWidth: {
      handler (val) {
        if (val <= 1000) {
          window.location.href = window.location.href.replace(
            '/hopfung/',
            '/mobile/'
          )
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100vh;
  min-width: 1366px;
  // overflow-x: hidden;
}
</style>
